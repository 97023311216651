export enum IntroModalType {
  DEFAULT_SHIFT = 'default-shift',
  BANNER_OVERLAY = 'banner-overlay',
  CONTACT_SALES = 'contact-sales ',
  ABSENCE_IN_DAYS = 'absence-in-days',
  QUICK_SCHEDULES = 'quick-schedules',
  GET_ADD_ON = 'get-add-on',
}

export interface IntroModal {
  title: string;
  emoji: string;
  innerHTML: string;
  externalUrl?: string;
  redirectLink?: string;
  image?: string;
  confirmationAction?: string;
  link?: string;
}

export interface IntroductionModalCloseEvent {
  showMe?: boolean;
}
