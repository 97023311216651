import { Injectable } from '@angular/core';
import { ScheduleSidebarService } from '@app/+authenticated/+schedule/employee/schedule-employee-n-period/schedule-sidebar.service';
import { ApiGateway } from '@app/api/ApiGateway.service';
import { DialogService } from 'primeng/dynamicdialog';
import { filter, map, Observable, take } from 'rxjs';

import { IntroductionModalComponent } from './introduction-modal.component';
import { introductionModalConfig } from './introduction-modal.config';
import { IntroductionModalCloseEvent, IntroModalType } from './introduction-modal.model';

@Injectable()
export class IntroductionModalService {
  public constructor(
    protected dialogService: DialogService,
    protected sidebarService: ScheduleSidebarService,
    private api: ApiGateway,
  ) {}

  public openDefaultShift(): void {
    this.dialogService
      .open(IntroductionModalComponent, {
        data: {
          introModalType: IntroModalType.DEFAULT_SHIFT,
          showMeButton: this.sidebarService.hasSidebar,
        },
        ...introductionModalConfig,
      })
      .onClose.pipe(
        take(1),
        filter((event: IntroductionModalCloseEvent) => event?.showMe),
      )
      // eslint-disable-next-line rxjs/no-ignored-subscription
      .subscribe(() => {
        this.sidebarService.openSideBar();
      });
  }

  public getSalesPhoneNumber(): Observable<string> {
    return this.api.get('accounts/contact_sales').pipe(map((data) => data?.sales_phone_nr ?? '+31 (0)79 808 01 33'));
  }
}
