import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { KnowledgeBaseArticleLinkModule } from '@app/pipes/knowledge-base-article-link.module';
import { AccountModel } from '@app/reducers/account/account.model';
import { getAccount } from '@app/reducers/account/account.service';
import { UserGuideStatus, UserGuideType } from '@app/reducers/orm/user-guide/user-guide.model';
import { UserGuideService } from '@app/reducers/orm/user-guide/user-guide.service';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subscription, take } from 'rxjs';

import { SharedPipeModule } from '../../../app/pipes/shared-pipe.module';
import { TranslationParamsPipe } from '../../../app/pipes/translation-params.pipe';
import { IconComponent } from '../../+authenticated/shared/icon.component';
import { IntroductionModalCloseEvent, IntroModal, IntroModalType } from './introduction-modal.model';
import { IntroductionModalService } from './introduction-modal.service';
import { alternativeMessages, introModalMessages } from './messages/';

@Component({
  selector: 'introduction-modal',
  templateUrl: './introduction-modal.component.html',
  imports: [
    NgIf,
    TranslateModule,
    SharedPipeModule,
    TranslationParamsPipe,
    IconComponent,
    NgClass,
    KnowledgeBaseArticleLinkModule,
    RouterModule,
    AsyncPipe,
  ],
  styleUrls: ['introduction-modal.component.scss'],
  standalone: true,
})
export class IntroductionModalComponent implements OnInit {
  public introModalType: IntroModalType;
  public firstName: string;
  public userGuideType: UserGuideType;
  public message: IntroModal;
  public countryCode: string;
  protected dataSubs = new Subscription();
  public readonly showMeButton: boolean;
  public readonly account$: Observable<AccountModel>;
  public customShowMeButtonText: string;
  public price: number;
  public salesPhoneNumber$: Observable<string>;

  public constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private router: Router,
    protected userGuideService: UserGuideService,
    private store: Store,
    private introductionModalService: IntroductionModalService,
  ) {
    this.account$ = this.store.select(getAccount);
    this.showMeButton = config.data?.showMeButton;
    this.customShowMeButtonText = config.data?.customShowMeButtonText;
    this.price = config.data?.price;
    this.salesPhoneNumber$ = this.introductionModalService.getSalesPhoneNumber();
  }

  public ngOnInit(): void {
    this.introModalType = this.config.data.introModalType;
    this.userGuideType = this.config?.data?.userGuideType;
    this.countryCode = this.config?.data?.countryCode;

    void this.store
      .select(getAccount)
      .pipe(take(1))
      .subscribe((account) => {
        this.countryCode = account?.country;
        this.firstName = account?.first_name;
        this.setMessage();
      });
  }

  private setMessage(): void {
    if (
      this.countryCode &&
      alternativeMessages[this.introModalType]?.applicableTo?.countryCodes?.includes(this.countryCode)
    ) {
      this.message = alternativeMessages[this.introModalType];
      return;
    }

    this.message = introModalMessages[this.introModalType];
  }

  public closeModal(closeEvent?: IntroductionModalCloseEvent): void {
    this.ref.close(closeEvent);

    if (!this.userGuideType) {
      return;
    }

    this.dataSubs.add(
      this.userGuideService
        .trackUserGuide({
          guide: this.userGuideType,
          status: UserGuideStatus.FINISHED,
        })
        .subscribe(),
    );
  }

  public navigateTo(navigationLink: string): void {
    this.router.navigate([navigationLink]);
    this.closeModal();
  }

  public onShowMe(): void {
    this.closeModal({ showMe: true });
  }
}
