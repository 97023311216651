<div class="introduction-modal-container mt-[30px] flex justify-center">
  <div class="absolute right-[20px] top-[10px]">
    <a class="close-icon" (click)="closeModal()">
      <icon class="si modal-close__icon h-[12px] w-[12px]" svgIcon="close"></icon>
    </a>
  </div>
  <div class="flex flex-col items-center px-20">
    <ng-container *ngIf="message?.image">
      <img class="mb-8 max-w-[45rem]" [src]="message?.image" />
    </ng-container>
    <ng-container *ngIf="message?.emoji">
      <span class="mb-[30px] text-[34px]">{{ message?.emoji }}</span>
    </ng-container>
    <div class="flex max-w-[90%] flex-col">
      <span class="header mb-4 text-center text-[20px] font-bold text-word-mark-800">{{
        message?.title | translate | translationParams: { price }
      }}</span>
    </div>
    <span
      [innerHTML]="
        message?.innerHTML
          | translate
          | translationParams: { phoneNr: (salesPhoneNumber$ | async), email: (account$ | async)?.support }
      "
      class="font-regular mb-8 text-center text-word-mark-500"
    >
    </span>
    <div
      class="mb-8 flex gap-6"
      *ngIf="
        message?.redirectLink || message?.externalUrl || message?.confirmationAction || message?.link || showMeButton
      "
    >
      <a
        *ngIf="message?.externalUrl"
        class="btn p-button-md p-button-outlined p-button-primary flex h-[34px] items-center self-center border-none bg-grey-200 px-4 font-medium text-grey-500 hover:bg-grey-300"
        [attr.href]="message?.externalUrl | knowledgeBaseArticleLink"
        target="_blank"
      >
        {{ 'Learn more' | translate }}
      </a>
      <button
        *ngIf="message?.redirectLink"
        class="btn p-button-md p-button-outlined p-button-primary flex h-[34px] items-center self-center border-none bg-purple-100 px-4 font-medium text-purple-500 hover:bg-purple-200"
        (click)="navigateTo(message?.redirectLink)"
      >
        {{ 'View changes' | translate }} <icon class="ml-1 h-[10px]" svgIcon="arrow-right"></icon>
      </button>
      <button
        *ngIf="showMeButton"
        class="btn p-button-md p-button-outlined p-button-primary flex h-[34px] items-center self-center border-none bg-purple-100 px-4 font-medium text-purple-500 hover:bg-purple-200"
        (click)="onShowMe()"
      >
        <ng-container *ngIf="customShowMeButtonText; else default">
          {{ customShowMeButtonText }}
        </ng-container>
        <ng-template #default>
          {{ 'Show me' | translate }} <icon class="ml-1 h-[10px]" svgIcon="arrow-right"></icon>
        </ng-template>
      </button>
      <button
        *ngIf="message?.confirmationAction"
        class="btn p-button-md p-button-outlined p-button-primary flex h-[34px] items-center self-center border-none bg-purple-100 px-4 font-medium text-[#7a5af8] hover:bg-[#ebe9fe]"
        (click)="closeModal()"
      >
        {{ message?.confirmationAction | translate }}
      </button>
      <button
        *ngIf="message?.link"
        class="btn p-button-md p-button-outlined p-button-primary flex h-[34px] items-center self-center border-none bg-purple-100 px-4 font-medium text-purple-500 hover:bg-purple-200"
        (click)="navigateTo(message?.link)"
      >
        {{ 'Get started' | translate }} <icon class="ml-1 h-[10px]" svgIcon="arrow-right"></icon>
      </button>
    </div>
  </div>
</div>
