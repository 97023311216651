import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { IntroModal, IntroModalType } from '../introduction-modal.model';

interface AlternativeIntroModal extends IntroModal {
  applicableTo: {
    countryCodes?: string[];
  };
}

export const alternativeMessages: Partial<Record<IntroModalType, AlternativeIntroModal>> = {
  [IntroModalType.ABSENCE_IN_DAYS]: {
    title: _('New in Shiftbase: Absence in days'),
    emoji: '🎉 🚀 🙌',
    innerHTML: _(
      "Introducing our latest update - Vacation and absences in days! Easily connect absence types to employees' balances, enabling convenient full and half-day requests. No more complex calculations or confusion. Set up balances and absence types in days instead of hours improve your time off management with our new feature Absences in days! If you want to learn how to migrate to this new feature, click on Learn more."
    ),
    externalUrl: 'absence-in-days',
    redirectLink: '/account/settings/modules/absence',
    applicableTo: {
      countryCodes: ['DE', 'CH', 'AT'],
    },
  },
};
