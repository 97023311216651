import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import { IntroModal, IntroModalType } from '../introduction-modal.model';

export const introModalMessages: Record<IntroModalType, IntroModal> = {
  [IntroModalType.DEFAULT_SHIFT]: {
    title: _('Great!'),
    emoji: '⚡️  🪄  👏 ',
    innerHTML: _(
      'You’ve created your first shift template. To edit your new shift template, simply click on the “Shifts/Teams” button to see the full list of your shift templates. ',
    ),
    externalUrl: 'managing-shift-types',
  },
  [IntroModalType.BANNER_OVERLAY]: {
    title: _('Yes, done!'),
    emoji: '📬  📩  ✅',
    innerHTML: _('We have resent your verification email, it will show up in your inbox soon.'),
    confirmationAction: _('Got it!'),
  },
  [IntroModalType.CONTACT_SALES]: {
    title: _('Please contact sales'),
    emoji: '🚀️  📞  🎯',
    innerHTML: _(
      'You have a custom contract. If you’re interested in changing your plan, please give us a call at [phoneNr]. Or, you can email us at [email]',
    ),
    confirmationAction: _('Got it!'),
  },
  [IntroModalType.ABSENCE_IN_DAYS]: {
    title: _('New in Shiftbase: Absence in days'),
    emoji: '🎉 🚀 🙌',
    innerHTML: _(
      'Introducing our latest update: Absence in days! Easily manage time off requests with our new feature. Connect absence types to a time off balance, allowing full and half-day requests. Customize to fit your needs, with greater control over employee time off. If you want to learn how to migrate to this new feature, click on Learn more.',
    ),
    externalUrl: 'absence-in-days',
    redirectLink: '/account/settings/modules/absence',
  },
  [IntroModalType.QUICK_SCHEDULES]: {
    title: _('New in Shiftbase: Schedule copying'),
    emoji: '🎉 🚀 🙌',
    innerHTML: _(
      'Easily replicate your daily or weekly schedules to various time slots. Whether you need the same staffing as the previous week or want to duplicate a unique staffing arrangement for special occasions, copying schedules streamlines the process, saving you time and ensuring smooth coordination.',
    ),
    externalUrl: 'copy-schedule',
    redirectLink: '/schedule/employee/week',
  },
  [IntroModalType.GET_ADD_ON]: {
    title: _('Get App Center Plus for [price]/month'),
    emoji: '',
    innerHTML: _(
      "With App Center Plus, in addition to our free integrations, you also get access to all 'Plus' integrations.",
    ),
    image: 'assets/images/app_center_plus_logo.svg',
  },
};
